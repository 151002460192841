import {
  faChartLine,
  faStickyNote,
  faStore,
  faTasks,
  faTicketAlt,
  faUtensils,
  faCheck,
  faUser,
  faImage,
  faCommentDots,
  faCalendar,
  faPeopleCarryBox,
  faYen,
  faMobileAlt,
  faTag,
} from '@fortawesome/free-solid-svg-icons';
import { AnimatePresence, motion } from 'framer-motion';
import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
// Layout Blueprints
import { Layout } from './layouts';
import { AuthStateHandler, OverlayLoader } from './reusable';
import AnycarryAddresses from './views/anycarry-address/AnycarryAddresses';
import CreateAnycarryAddress from './views/anycarry-address/CreateAnycarryAddress';
import EditAnycarryAddress from './views/anycarry-address/EditAnycarryAddress';
import CrewLevels from './views/crew-level/CrewLevels';
import CreateCrewLevel from './views/crew-level/CreateCrewLevel';
import EditCrewLevel from './views/crew-level/EditCrewLevel';
import CheckAppVersion from './CheckAppVersion';

// Example Pages

const SignIn = lazy(() => import('./pages/SignIn'));

const Dashboard = lazy(() => import('./views/dashboard/dashboard-order/Dashboard'));

const Shops = lazy(() => import('./views/shop/Shops'));

const ElementItems = lazy(() => import('./views/element-item/ElementItems'));
const CreateElementItem = lazy(() => import('./views/element-item/CreateElementItem'));
const EditElementItem = lazy(() => import('./views/element-item/EditElementItem'));

const ElementItemTags = lazy(() => import('./views/element-item-tag/ElementItemTags'));
const CreateElementItemTag = lazy(() => import('./views/element-item-tag/CreateElementItemTag'));
const EditElementItemTag = lazy(() => import('./views/element-item-tag/EditElementItemTag'));

const CrewShiftsList = lazy(() => import('./views/crew-shift/CrewShiftsList'));
const EditCrewShift = lazy(() => import('./views/crew-shift/EditCrewShift'));
const CreateCrewShift = lazy(() => import('./views/crew-shift/CreateCrewShift'));
const CrewShiftsManage = lazy(() => import('./views/crew-shift/CrewShiftsManage'));
const CrewTasks = lazy(() => import('./views/crew-task/CrewTasks'));
const CreateCrewTask = lazy(() => import('./views/crew-task/CreateCrewTask'));
const EditCrewTask = lazy(() => import('./views/crew-task/EditCrewTask'));

const WelcomeBanners = lazy(() => import('./views/welcome-banner/WelcomeBanners'));
const CreateWelcomeBanner = lazy(() => import('./views/welcome-banner/CreateWelcomeBanner'));
const EditWelcomeBanner = lazy(() => import('./views/welcome-banner/EditWelcomeBanner'));

const Passes = lazy(() => import('./views/pass/Passes'));
const CreatePass = lazy(() => import('./views/pass/CreatePass'));
const EditPass = lazy(() => import('./views/pass/EditPass'));

const MessageTemplates = lazy(() => import('./views/message-template/MessageTemplates'));
const CreateMessageTemplate = lazy(() => import('./views/message-template/CreateMessageTemplate'));
const EditMessageTemplate = lazy(() => import('./views/message-template/EditMessageTemplate'));

const TrainingSheets = lazy(() => import('./views/training-sheet/TrainingSheets'));
const Admins = lazy(() => import('./views/admin/Admins'));
const CreateTrainingSheet = lazy(() => import('./views/training-sheet/CreateTrainingSheet'));
const EditTrainingSheet = lazy(() => import('./views/training-sheet/EditTrainingSheet'));

const EditCrewSetting = lazy(() => import('./views/crew-setting/EditCrewSetting'));

const NavigatorTalks = lazy(() => import('./views/navigator-talk/NavigatorTalks'));
const CreateNavigatorTalk = lazy(() => import('./views/navigator-talk/CreateNavigatorTalk'));
const EditNavigatorTalk = lazy(() => import('./views/navigator-talk/EditNavigatorTalk'));

const SalesComponents = lazy(() => import('./views/sales/sales-component/SalesComponents'));
const CreateSalesComponent = lazy(() => import('./views/sales/sales-component/CreateSalesComponent'));
const EditSalesComponent = lazy(() => import('./views/sales/sales-component/EditSalesComponent'));

const ProductTypes = lazy(() => import('./views/product-type/ProductTypes'));
const CreateProductType = lazy(() => import('./views/product-type/CreateProductType'));
const EditProductType = lazy(() => import('./views/product-type/EditProductType'));

const SalesItems = lazy(() => import('./views/sales/sales-item/SalesItems'));
const CreateSalesItem = lazy(() => import('./views/sales/sales-item/CreateSalesItem'));
const EditSalesItem = lazy(() => import('./views/sales/sales-item/EditSalesItem'));

const SalesPresets = lazy(() => import('./views/sales/sales-preset/SalesPresets'));
const CreateSalesPreset = lazy(() => import('./views/sales/sales-preset/CreateSalesPreset'));
const EditSalesPreset = lazy(() => import('./views/sales/sales-preset/EditSalesPreset'));

const SalesPresetShops = lazy(() => import('./views/sales/sales-preset-shop/SalesPresetShops'));
const EditSalesPresetShops = lazy(() => import('./views/sales/sales-preset-shop/EditSalesPresetShops'));

const SalesItemShops = lazy(() => import('./views/sales/sales-item-shop/SalesItemShops'));
const EditSalesItemShops = lazy(() => import('./views/sales/sales-item-shop/EditSalesItemShops'));

const SalesShops = lazy(() => import('./views/sales/sales-shop/SalesShops'));
const EditSalesShop = lazy(() => import('./views/sales/sales-shop/EditSalesShop'));

const TaxRates = lazy(() => import('./views/tax-rate/TaxRates'));
const EditTaxRate = lazy(() => import('./views/tax-rate/EditTaxRate'));

const FeedbackReasons = lazy(() => import('./views/feedback-reason/FeedbackReasons'));
const EditFeedbackReason = lazy(() => import('./views/feedback-reason/EditFeedbackReason'));
const CreateFeedbackReason = lazy(() => import('./views/feedback-reason/CreateFeedbackReason'));

const StampCardPlans = lazy(() => import('./views/stamp-card-plan/StampCardPlans'));
const CreateStampCardPlan = lazy(() => import('./views/stamp-card-plan/CreateStampCardPlan'));
const EditStampCardPlan = lazy(() => import('./views/stamp-card-plan/EditStampCardPlan'));

const LabelPrinters = lazy(() => import('./views/label-printer/LabelPrinters'));
const CreateLabelPrinter = lazy(() => import('./views/label-printer/CreateLabelPrinter'));
const EditLabelPrinter = lazy(() => import('./views/label-printer/EditLabelPrinter'));

const RecipeTags = lazy(() => import('./views/recipe-tag/RecipeTags'));
const CreateRecipeTag = lazy(() => import('./views/recipe-tag/CreateRecipeTag'));
const EditRecipeTag = lazy(() => import('./views/recipe-tag/EditRecipeTag'));

const RecipeComponentGroups = lazy(() => import('./views/recipe-component-group/RecipeComponentGroups'));
const CreateRecipeComponentGroup = lazy(() => import('./views/recipe-component-group/CreateRecipeComponentGroup'));
const EditRecipeComponentGroup = lazy(() => import('./views/recipe-component-group/EditRecipeComponentGroup'));

export const routes = [
  { path: '/', exact: true, name: 'ホーム' },
  {
    path: '/dashboard',
    exact: true,
    title: 'ダッシュボード',
    icon: faChartLine,
    component: Dashboard,
  },

  { path: '/shops', exact: true, title: '店舗', icon: faStore, component: Shops },

  {
    path: '/element_items',
    exact: true,
    title: 'エレメントアイテム',
    icon: faUtensils,
    component: ElementItems,
  },
  {
    path: '/element_items/create',
    exact: true,
    title: 'エレメントアイテム作成',
    icon: faUtensils,
    component: CreateElementItem,
  },
  {
    path: '/element_items/:elementItemUid/edit',
    exact: true,
    title: 'エレメントアイテム編集',
    icon: faUtensils,
    component: EditElementItem,
  },
  {
    path: '/element_item_tags',
    exact: true,
    title: 'エレメントアイテムタグ',
    icon: faTag,
    component: ElementItemTags,
  },
  {
    path: '/element_item_tags/create',
    exact: true,
    title: 'エレメントアイテムタグ作成',
    icon: faTag,
    component: CreateElementItemTag,
  },
  {
    path: '/element_item_tags/:elementItemTagUid/edit',
    exact: true,
    title: 'エレメントアイテムタグ編集',
    icon: faTag,
    component: EditElementItemTag,
  },
  { path: '/crew_shifts', exact: true, title: 'クルーシフト', icon: faCalendar, component: CrewShiftsList },
  {
    path: '/crew_shifts/create',
    exact: true,
    title: 'クルーシフト作成',
    icon: faUtensils,
    component: CreateCrewShift,
  },
  {
    path: '/crew_shifts/:crewShiftUid/edit',
    exact: true,
    title: 'クルーシフト編集',
    icon: faCalendar,
    component: EditCrewShift,
  },
  { path: '/crew_shifts/manage', exact: true, title: 'クルーシフト', icon: faCalendar, component: CrewShiftsManage },
  { path: '/crew_tasks', exact: true, title: 'クルータスク', icon: faTasks, component: CrewTasks },
  {
    path: '/crew_tasks/create',
    exact: true,
    title: 'クルータスク作成',
    icon: faTasks,
    component: CreateCrewTask,
  },
  {
    path: '/crew_tasks/:crewTaskUid/edit',
    exact: true,
    title: 'クルータスク編集',
    icon: faTasks,
    component: EditCrewTask,
  },
  { path: '/crew_levels', exact: true, title: 'クルーレベル', icon: faTasks, component: CrewLevels },
  {
    path: '/crew_levels/create',
    exact: true,
    title: 'クルーレベル作成',
    icon: faTasks,
    component: CreateCrewLevel,
  },
  {
    path: '/crew_levels/:crewLevelUid/edit',
    exact: true,
    title: 'クルーレベル編集',
    icon: faTasks,
    component: EditCrewLevel,
  },
  { path: '/welcome_banners', exact: true, title: 'ウェルカムバナー', icon: faImage, component: WelcomeBanners },
  {
    path: '/welcome_banners/create',
    exact: true,
    title: 'ウェルカムバナー作成',
    icon: faImage,
    component: CreateWelcomeBanner,
  },
  {
    path: '/welcome_banners/:welcomeBannerUid/edit',
    exact: true,
    title: 'ウェルカムバナー編集',
    icon: faImage,
    component: EditWelcomeBanner,
  },

  { path: '/passes', exact: true, title: 'パス', icon: faTicketAlt, component: Passes },
  { path: '/passes/create', exact: true, title: 'パス作成', icon: faUtensils, component: CreatePass },
  { path: '/passes/:passUid/edit', exact: true, title: 'パス編集', icon: faUtensils, component: EditPass },

  {
    path: '/message_templates',
    exact: true,
    title: 'メッセージテンプレート',
    icon: faStickyNote,
    component: MessageTemplates,
  },
  {
    path: '/message_templates/create',
    exact: true,
    title: 'メッセージテンプレート作成',
    icon: faStickyNote,
    component: CreateMessageTemplate,
  },
  {
    path: '/message_templates/:messageTemplateUid/edit',
    exact: true,
    title: 'メッセージテンプレート編集',
    icon: faStickyNote,
    component: EditMessageTemplate,
  },

  {
    path: '/training_sheets',
    exact: true,
    title: 'トレーニングシート',
    icon: faCheck,
    component: TrainingSheets,
  },
  {
    path: '/training_sheets/create',
    exact: true,
    title: 'トレーニングシート作成',
    icon: faCheck,
    component: CreateTrainingSheet,
  },
  {
    path: '/training_sheets/:trainingSheetUid/edit',
    exact: true,
    title: 'トレーニングシート編集',
    icon: faCheck,
    component: EditTrainingSheet,
  },
  {
    path: '/crew_setting',
    exact: true,
    title: 'クルーアプリ設定',
    icon: faCommentDots,
    component: EditCrewSetting,
  },
  {
    path: '/admins',
    exact: true,
    title: '管理者(クルー含む)',
    icon: faUser,
    component: Admins,
  },
  {
    path: '/navigator_talks',
    exact: true,
    title: '接客トーク',
    icon: faCommentDots,
    component: NavigatorTalks,
  },
  {
    path: '/navigator_talks/create',
    exact: true,
    title: '接客トーク作成',
    icon: faCommentDots,
    component: CreateNavigatorTalk,
  },
  {
    path: '/navigator_talks/:navigatorTalkUid/edit',
    exact: true,
    title: '接客トーク編集',
    icon: faCommentDots,
    component: EditNavigatorTalk,
  },
  {
    path: '/product_types',
    exact: true,
    title: '商品種別',
    icon: faUtensils,
    component: ProductTypes,
  },
  {
    path: '/product_types/create',
    exact: true,
    title: '商品種別作成',
    icon: faUtensils,
    component: CreateProductType,
  },
  {
    path: '/product_types/:productTypeUid/edit',
    exact: true,
    title: '商品種別編集',
    icon: faUtensils,
    component: EditProductType,
  },

  {
    path: '/sales_components',
    exact: true,
    title: '販売コンポーネント',
    icon: faUtensils,
    component: SalesComponents,
  },
  {
    path: '/sales_components/create',
    exact: true,
    title: '販売コンポーネント作成',
    icon: faUtensils,
    component: CreateSalesComponent,
  },
  {
    path: '/sales_components/:salesComponentUid/edit',
    exact: true,
    title: '販売コンポーネント編集',
    icon: faUtensils,
    component: EditSalesComponent,
  },
  {
    path: '/sales_items',
    exact: true,
    title: '販売アイテム',
    icon: faUtensils,
    component: SalesItems,
  },
  {
    path: '/sales_items/create',
    exact: true,
    title: '販売アイテム作成',
    icon: faUtensils,
    component: CreateSalesItem,
  },
  {
    path: '/sales_items/:salesItemUid/edit',
    exact: true,
    title: '販売アイテム編集',
    icon: faUtensils,
    component: EditSalesItem,
  },
  {
    path: '/sales_presets',
    exact: true,
    title: '販売プリセット',
    icon: faUtensils,
    component: SalesPresets,
  },
  {
    path: '/sales_presets/:curryRecipeType',
    exact: true,
    title: '販売プリセット',
    icon: faUtensils,
    component: SalesPresets,
  },
  {
    path: '/product_types/:productType/sales_presets/:curryRecipeType/create',
    exact: true,
    title: '販売プリセット作成',
    icon: faUtensils,
    component: CreateSalesPreset,
  },
  {
    path: '/sales_presets/:curryRecipeType/:salesPresetUid/edit',
    exact: true,
    title: '販売プリセット編集',
    icon: faUtensils,
    component: EditSalesPreset,
  },

  {
    path: '/sales_preset_shops/:curryRecipeType/',
    exact: true,
    title: '販売プリセット提供店舗',
    icon: faUtensils,
    component: SalesPresetShops,
  },
  {
    path: '/edit_sales_preset_shops/:curryRecipeType/',
    exact: true,
    title: '販売プリセット提供店舗編集',
    icon: faUtensils,
    component: EditSalesPresetShops,
  },
  {
    path: '/sales_item_shops',
    exact: true,
    title: '販売アイテム提供店舗',
    icon: faUtensils,
    component: SalesItemShops,
  },
  {
    path: '/edit_sales_item_shops',
    exact: true,
    title: '販売アイテム提供店舗編集',
    icon: faUtensils,
    component: EditSalesItemShops,
  },

  {
    path: '/sales_shops',
    exact: true,
    title: '販売店舗一覧',
    icon: faStore,
    component: SalesShops,
  },
  {
    path: '/sales_shops/:salesShopUid/edit',
    exact: true,
    title: '販売店舗編集',
    icon: faStore,
    component: EditSalesShop,
  },

  {
    path: '/tax_rates',
    exact: true,
    title: '販売チャネル別税率一覧',
    icon: faYen,
    component: TaxRates,
  },
  {
    path: '/tax_rates/:salesChannel/edit',
    exact: true,
    title: '販売チャネル別税率',
    icon: faYen,
    component: EditTaxRate,
  },
  {
    path: '/feedback_reasons',
    exact: true,
    title: 'フィードバック評価理由設定',
    icon: faMobileAlt,
    component: FeedbackReasons,
  },
  {
    path: '/feedback_reasons/create',
    exact: true,
    title: 'フィードバック評価理由作成',
    icon: faMobileAlt,
    component: CreateFeedbackReason,
  },
  {
    path: '/feedback_reasons/:feedbackReasonId/edit',
    exact: true,
    title: 'フィードバック評価理由編集',
    icon: faMobileAlt,
    component: EditFeedbackReason,
  },
  {
    path: '/stamp_card_plans',
    exact: true,
    title: 'スタンプカード一覧',
    icon: faMobileAlt,
    component: StampCardPlans,
  },
  {
    path: '/stamp_card_plans/create',
    exact: true,
    title: 'スタンプカード作成',
    icon: faMobileAlt,
    component: CreateStampCardPlan,
  },
  {
    path: '/stamp_card_plans/:stampCardPlanUid/edit',
    exact: true,
    title: 'スタンプカード編集',
    icon: faMobileAlt,
    component: EditStampCardPlan,
  },

  {
    path: '/anycarry_addresses',
    exact: true,
    title: 'エニキャリ/Wolt/TIMEBOX住所',
    icon: faPeopleCarryBox,
    component: AnycarryAddresses,
  },
  {
    path: '/anycarry_addresses/create',
    exact: true,
    title: 'エニキャリ/Wolt/TIMEBOX住所作成',
    icon: faPeopleCarryBox,
    component: CreateAnycarryAddress,
  },
  {
    path: '/anycarry_addresses/:anycarryAddressUid/edit',
    exact: true,
    title: 'エニキャリ/Wolt/TIMEBOX住所編集',
    icon: faPeopleCarryBox,
    component: EditAnycarryAddress,
  },
  {
    path: '/label_printers',
    exact: true,
    title: 'ラベルプリンター一覧',
    icon: faMobileAlt,
    component: LabelPrinters,
  },
  {
    path: '/label_printers/create',
    exact: true,
    title: 'ラベルプリンター作成',
    icon: faMobileAlt,
    component: CreateLabelPrinter,
  },
  {
    path: '/label_printers/:labelPrinterUid/edit',
    exact: true,
    title: 'ラベルプリンター編集',
    icon: faMobileAlt,
    component: EditLabelPrinter,
  },
  {
    path: '/recipe_tags',
    exact: true,
    title: 'レシピタグ',
    icon: faTag,
    component: RecipeTags,
  },
  {
    path: '/recipe_tags/create',
    exact: true,
    title: 'レシピタグ作成',
    icon: faTag,
    component: CreateRecipeTag,
  },
  {
    path: '/recipe_tags/:recipeTagUid/edit',
    exact: true,
    title: 'レシピタグ編集',
    icon: faTag,
    component: EditRecipeTag,
  },
  {
    path: '/recipe_component_groups',
    exact: true,
    title: 'コンポーネントグループ',
    icon: faTag,
    component: RecipeComponentGroups,
  },
  {
    path: '/recipe_component_groups/create',
    exact: true,
    title: 'コンポーネントグループ作成',
    icon: faTag,
    component: CreateRecipeComponentGroup,
  },
  {
    path: '/recipe_component_groups/:recipeComponentGroupUid/edit',
    exact: true,
    title: 'コンポーネントグループ編集',
    icon: faTag,
    component: EditRecipeComponentGroup,
  },
];

const AppRouter = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.3,
  };

  return (
    <AnimatePresence>
      <Suspense fallback={<OverlayLoader />}>
        <CheckAppVersion />
        <Switch>
          <Route exact path="/signin" component={SignIn} />
          <AuthStateHandler>
            <Layout>
              <Switch location={location} key={location.pathname}>
                <Redirect exact from="/" to="/dashboard" />
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}
                >
                  {routes.map((route) => {
                    return (
                      route.component && (
                        <Route
                          path={route.path}
                          exact={route.exact}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          render={(props: any) => <route.component {...props} title={route.title} icon={route.icon} />}
                        />
                      )
                    );
                  })}
                </motion.div>
              </Switch>
            </Layout>
          </AuthStateHandler>
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
};

export default AppRouter;
