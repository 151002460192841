import React, { FC } from 'react';
import { DataGrid, GridColDef, GridToolbar, jaJP } from '@mui/x-data-grid';
import { useDialog } from 'muibox';

import { EditButton, DeleteButton } from '../../reusable';
import AnycarryAddress from '../../models/anycarry-address/AnycarryAddress';

type Props = {
  anycarryAddresses: AnycarryAddress[];
};

const anycarryAddressTable: FC<Props> = (props) => {
  const dialog = useDialog();

  const handleDelete = async (anycarryAddress: AnycarryAddress) => {
    const result = await dialog.confirm({ message: `「${anycarryAddress.name}」を削除します。よろしいですか？` });
    if (!result) return;

    try {
      await AnycarryAddress.delete(anycarryAddress);
    } catch (e) {
      alert(e);
    }
  };

  const cols: GridColDef[] = [
    {
      field: 'actions',
      headerName: '操作',
      renderCell: (params) => (
        <>
          <EditButton to={`/anycarry_addresses/${params.row.snapshot!.id}/edit`} />
          <DeleteButton onClick={() => handleDelete(params.row.anycarryAddress)} />
        </>
      ),
    },
    {
      field: 'zipcode',
      headerName: '郵便番号',
    },
    {
      field: 'name',
      headerName: '名前',
      width: 200,
    },
    {
      field: 'memo',
      headerName: 'メモ',
      width: 200,
    },
    {
      field: 'state',
      headerName: '都道府県',
    },
    {
      field: 'city',
      headerName: '市区町村',
      width: 200,
    },
    {
      field: 'address1',
      headerName: '店舗番地',
      width: 200,
    },
    {
      field: 'address2',
      headerName: '建物名',
      width: 200,
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        columns={cols}
        rows={props.anycarryAddresses.map((anycarryAddress) => ({
          ...anycarryAddress,
          id: anycarryAddress.snapshot!.id,
        }))}
        slots={{
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true },
          },
        }}
        autoHeight
      />
    </div>
  );
};

export default anycarryAddressTable;
