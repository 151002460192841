import { Card, CardContent, CardHeader, Grid, Box, Switch, FormControlLabel } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import AnycarryAddress from '../../../../models/anycarry-address/AnycarryAddress';
import { TextFormControl } from '../../../../reusable';

type Props = {
  anycarryAddress?: AnycarryAddress;
};

const AnycarryAddressForm: FC<Props> = (props) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Card>
            <CardHeader title="住所" />
            <CardContent>
              <Grid item container spacing={2}>
                <Grid item sm={12}>
                  <TextFormControl label="名前" name="name" defaultValue={props.anycarryAddress?.name} required />
                </Grid>
                <Grid item sm={12}>
                  <TextFormControl
                    label="メモ (内部管理用 / API連携では使用されない)"
                    name="memo"
                    defaultValue={props.anycarryAddress?.memo}
                  />
                </Grid>
                <Grid item sm={12}>
                  <TextFormControl
                    label="郵便番号(ハイフンの有無は任意)"
                    name="zipcode"
                    defaultValue={props.anycarryAddress?.zipcode}
                    required
                  />
                </Grid>

                <Grid item sm={12}>
                  <TextFormControl label="都道府県" name="state" defaultValue={props.anycarryAddress?.state} required />
                </Grid>

                <Grid item sm={12}>
                  <TextFormControl label="市区町村" name="city" defaultValue={props.anycarryAddress?.city} required />
                </Grid>

                <Grid item sm={12}>
                  <TextFormControl
                    label="店舗番地"
                    name="address1"
                    defaultValue={props.anycarryAddress?.address1}
                    required
                  />
                </Grid>
                <Grid item sm={12}>
                  <TextFormControl
                    label="建物名"
                    name="address2"
                    defaultValue={props.anycarryAddress?.address2}
                    required
                  />
                </Grid>
                <Grid item sm={12}>
                  <TextFormControl label="電話番号" name="tel" defaultValue={props.anycarryAddress?.tel} required />
                </Grid>
                <Grid item sm={12}>
                  <TextFormControl
                    label="メールアドレス"
                    name="email"
                    defaultValue={props.anycarryAddress?.email}
                    required
                  />
                </Grid>
                <Grid item sm={12}>
                  <TextFormControl
                    label="Wolt Venue ID (Wolt配達元の場合)"
                    name="wolt_venue_id"
                    defaultValue={props.anycarryAddress?.woltVenueId}
                  />
                </Grid>

                <Grid item sm={12}>
                  <TextFormControl
                    label="ピックアップコメント (Wolt/TIMEBOX配達元の場合)"
                    name="pickup_comment"
                    defaultValue={props.anycarryAddress?.pickupComment}
                    multiline
                  />
                </Grid>

                <Grid item sm={12}>
                  <TextFormControl
                    label="緯度(lat) (Wolt配達先の場合)"
                    name="lat"
                    defaultValue={props.anycarryAddress?.lat}
                  />
                </Grid>

                <Grid item sm={12}>
                  <TextFormControl
                    label="経度(lng) (Wolt配達先の場合)"
                    name="lng"
                    defaultValue={props.anycarryAddress?.lng}
                  />
                </Grid>

                <Grid item sm={12}>
                  <TextFormControl
                    label="ドロップオフコメント (Wolt/TIMEBOX配達先の場合)"
                    name="dropoff_comment"
                    defaultValue={props.anycarryAddress?.dropoffComment}
                    multiline
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default AnycarryAddressForm;
