import {
  faChartLine,
  faStore,
  faUtensils,
  faUsers,
  faBullhorn,
  faComments,
  faUsersCog,
  faMobileAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from '@mui/material';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { NavLink } from 'react-router-dom';

const SidebarMenu = () => {
  return (
    <>
      <PerfectScrollbar>
        <div className="sidebar-navigation">
          <div className="sidebar-header">
            <span>ナビゲーション</span>
          </div>
          <ul>
            <li>
              <NavLink to="/dashboard">
                <span className="sidebar-icon">
                  <FontAwesomeIcon icon={faChartLine} />
                </span>
                <span className="sidebar-item-label">ダッシュボード</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/sales_preset_shops">
                <span className="sidebar-icon">
                  <FontAwesomeIcon icon={faUtensils} />
                </span>
                <span className="sidebar-item-label">商品管理</span>
              </NavLink>
              <Collapse in>
                <ul>
                  <li>
                    <NavLink to="/product_types">商品種別</NavLink>
                  </li>
                  <li>
                    <NavLink to="/sales_preset_shops/app/">アプリプリセット</NavLink>
                    <Collapse in>
                      <ul>
                        <li>
                          <NavLink to="/sales_preset_shops/app/">販売プリセット提供店舗</NavLink>
                        </li>
                        <li>
                          <NavLink to="/sales_presets/app/">販売プリセット一覧</NavLink>
                        </li>
                      </ul>
                    </Collapse>
                  </li>
                  <li>
                    <NavLink to="/sales_preset_shops/bulk_order/">一括注文プリセット</NavLink>
                    <Collapse in>
                      <ul>
                        <li>
                          <NavLink to="/sales_preset_shops/bulk_order/">販売プリセット提供店舗</NavLink>
                        </li>
                        <li>
                          <NavLink to="/sales_presets/bulk_order/">販売プリセット一覧</NavLink>
                        </li>
                      </ul>
                    </Collapse>
                  </li>
                  <li>
                    <NavLink to="/sales_item_shops">販売アイテム</NavLink>
                    <Collapse in>
                      <ul>
                        <li>
                          <NavLink to="/sales_item_shops">販売アイテム提供店舗</NavLink>
                        </li>
                        <li>
                          <NavLink to="/sales_items">販売アイテム一覧</NavLink>
                        </li>
                      </ul>
                    </Collapse>
                  </li>
                  <li>
                    <NavLink to="/sales_components">販売コンポーネント</NavLink>
                  </li>
                  <li>
                    <NavLink to="/recipe_component_groups">コンポーネントグループ</NavLink>
                  </li>
                  <li>
                    <NavLink to="/recipe_tags">レシピタグ</NavLink>
                  </li>
                  <li>
                    <NavLink to="/element_items">エレメントアイテム</NavLink>
                  </li>
                  <li>
                    <NavLink to="/element_item_tags">エレメントアイテムタグ</NavLink>
                  </li>
                  <li>
                    <NavLink to="/sales_shops">店舗個別販売設定</NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li>
              <NavLink to="/shops">
                <span className="sidebar-icon">
                  <FontAwesomeIcon icon={faStore} />
                </span>
                <span className="sidebar-item-label">店舗管理</span>
              </NavLink>
              <Collapse in>
                <ul>
                  <li>
                    <NavLink to="/tax_rates">販売チャネル別税率</NavLink>
                  </li>
                  <li>
                    <NavLink to="/anycarry_addresses">エニキャリ/Wolt/TIMEBOX住所</NavLink>
                  </li>
                  <li>
                    <NavLink to="/label_printers">ラベルプリンター</NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li>
              <NavLink to="/crew_shifts">
                <span className="sidebar-icon">
                  <FontAwesomeIcon icon={faUsers} />
                </span>
                <span className="sidebar-item-label">クルー管理</span>
              </NavLink>
              <Collapse in>
                <ul>
                  <li>
                    <NavLink to="/crew_shifts">クルーシフト</NavLink>
                    <Collapse in>
                      <ul>
                        <li>
                          <NavLink to="/crew_shifts">一覧</NavLink>
                        </li>
                        <li>
                          <NavLink to="/crew_shifts/manage">アップロード/ダウンロード</NavLink>
                        </li>
                      </ul>
                    </Collapse>
                  </li>
                  <li>
                    <NavLink to="/navigator_talks">接客トーク</NavLink>
                  </li>
                  <li>
                    <NavLink to="/crew_tasks">クルータスク</NavLink>
                  </li>
                  <li>
                    <NavLink to="/crew_levels">クルーレベル</NavLink>
                  </li>
                  <li>
                    <NavLink to="/training_sheets">トレーニングシート</NavLink>
                  </li>{' '}
                  <li>
                    <NavLink to="/crew_setting">クルーアプリ設定</NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li>
              <NavLink to="/welcome_banners">
                <span className="sidebar-icon">
                  <FontAwesomeIcon icon={faBullhorn} />
                </span>
                <span className="sidebar-item-label">マーケティング</span>
              </NavLink>
              <Collapse in>
                <ul>
                  <li>
                    <NavLink to="/welcome_banners">ウェルカムバナー</NavLink>
                  </li>
                  <li>
                    <NavLink to="/passes">パス</NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>

            <li>
              <NavLink to="/message_templates">
                <span className="sidebar-icon">
                  <FontAwesomeIcon icon={faComments} />
                </span>
                <span className="sidebar-item-label">メッセージ</span>
              </NavLink>
              <Collapse in>
                <ul>
                  <li>
                    <NavLink to="/message_templates">メッセージテンプレート</NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>

            <li>
              <NavLink to="/stamp_card_plans">
                <span className="sidebar-icon">
                  <FontAwesomeIcon icon={faMobileAlt} />
                </span>
                <span className="sidebar-item-label">アプリ設定</span>
              </NavLink>
              <Collapse in>
                <ul>
                  <li>
                    <NavLink to="/stamp_card_plans">スタンプカード</NavLink>
                  </li>
                  <li>
                    <NavLink to="/feedback_reasons">フィードバック評価理由設定</NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>

            <li>
              <NavLink to="/admins">
                <span className="sidebar-icon">
                  <FontAwesomeIcon icon={faUsersCog} />
                </span>
                <span className="sidebar-item-label">管理者(クルー含む)</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

export default SidebarMenu;
